import SalesApplicationTariffStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationTariffStep";
// import SalesApplicationFundingProjectsStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationFundingProjectsStep";
import SalesApplicationPersonalDetailsStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationPersonalDetailsStep";
import SalesApplicationPaymentStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationPaymentStep";
import SalesApplicationDeliveryStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationDeliveryStep";
import SalesApplicationCompleteStep from "@/features/sales-application-route/components/SalesApplicationSteps/steps/SalesApplicationCompleteStep";

export const salesApplicationSteps = {
  SalesApplicationTariffStep,
  // SalesApplicationFundingProjectsStep,
  SalesApplicationPersonalDetailsStep,
  SalesApplicationPaymentStep,
  SalesApplicationDeliveryStep,
  SalesApplicationCompleteStep,
};
