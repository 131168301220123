































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  PropType,
  watch,
} from "@vue/composition-api";
import { subYears, startOfDay, getYear } from "date-fns";

import store from "@/store";

import InfoPopup from "@/shared/components/InfoPopup";

import SalesApplicationStepContent from "../../../SalesApplicationStepContent";
import { SalesApplicationStepState } from "../../../SalesApplicationStep";
import SalesApplicationStepForm from "../../../SalesApplicationStepForm";
import SalesApplicationSalutations from "../../../SalesApplicationSalutations";
import SalesApplicationBranchTypes from "../../../SalesApplicationBranchTypes";

import SalesApplicationPersonalDetailsStepOverview from "./SalesApplicationPersonalDetailsStepOverview.vue";
import { CustomerType } from "@/api/sap";
import { DeliveryType } from "../SalesApplicationDeliveryStep";

export default defineComponent({
  name: "SalesApplicationPersonalDetailsStep",
  components: {
    InfoPopup,
    SalesApplicationStepContent,
    SalesApplicationStepForm,
    SalesApplicationSalutations,
    SalesApplicationBranchTypes,
    SalesApplicationPersonalDetailsStepOverview,
  },
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: SalesApplicationStepState.unvisited,
    },
  },
  setup(props) {
    const minBirthdate = subYears(startOfDay(new Date()), 100);
    const maxBirthdate = subYears(startOfDay(new Date()), 18);
    const datepickerRangeErrors = ref<string[]>([]);
    const visibleYears = getYear(maxBirthdate) - getYear(minBirthdate);

    const isBusinessCustomer = ref(
      store.getters.salesApplicationRouteIsBusinessCustomer
    );

    const isTariffChange = computed(
      () =>
        store.getters.salesApplicationDeliveryFormData.deliveryMethod ===
        DeliveryType.TARIFF_CHANGE
    );

    // Disable Validations for Tariff Changes
    const ruleRequired40 = isTariffChange.value ? "" : "required|max:40";
    const ruleRequired60 = isTariffChange.value ? "" : "required|max:60";
    const ruleEmail = isTariffChange.value ? "" : "required|email|max:241";
    const ruleBirthdate =
      isTariffChange.value || isBusinessCustomer.value
        ? "max:14"
        : "required|max:14";

    const abilities = store.getters.salesApplicationAbilities;
    const personalDetailAbilities =
      abilities["salesApplicationPersonalDataDetailsStep"];

    const { canAdvertiseCustomer } = personalDetailAbilities;

    const formData = ref({
      ...store.getters.salesApplicationPersonalDetailsFormData,
    });

    const initialData = store.getters.salesApplicationPersonalDetailsFormData;

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === SalesApplicationStepState.visited
        ) {
          formData.value = {
            ...store.getters.salesApplicationPersonalDetailsFormData,
          };
        }
      }
    );

    function onSubmit() {
      store.commit.setPersonalDetails(formData.value);
    }

    const isDisabled = (fieldName: string, value?: any) =>
      value
        ? !personalDetailAbilities.canEdit ||
          personalDetailAbilities.restrictedFields?.includes(fieldName)
        : !personalDetailAbilities.canEditWhenEmpty;

    return {
      formData,
      initialData,
      minBirthdate,
      maxBirthdate,
      visibleYears,
      datepickerRangeErrors,
      CustomerType,
      isDisabled,
      ruleRequired40,
      ruleRequired60,
      ruleEmail,
      ruleBirthdate,
      canAdvertiseCustomer,
      isBusinessCustomer,
      isTariffChange,
      onSubmit,
    };
  },
});
