

























import { defineComponent, computed } from "@vue/composition-api";

import { getSalutationTitle, getBranchTypeTitle } from "@/api/sap";
import store from "@/store";

import {
  SalesApplicationOverviewDataTable,
  SalesApplicationOverviewTextWithIconIndicator,
} from "../../../SalesApplicationOverview";

export default defineComponent({
  name: "SalesApplicationPersonalDetailsStepOverview",
  components: {
    SalesApplicationOverviewDataTable,
    SalesApplicationOverviewTextWithIconIndicator,
  },
  setup() {
    const isBusinessCustomer = computed(
      () => store.getters.salesApplicationRouteIsBusinessCustomer
    );

    const salesApplicationPersonalDetailsStepData = computed(
      () =>
        store.state.salesApplicationRoute.formData
          .salesApplicationPersonalDetailsStep
    );

    const details = computed(() =>
      [
        {
          title: "Anrede",
          value:
            (isBusinessCustomer.value
              ? getSalutationTitle(
                  salesApplicationPersonalDetailsStepData.value
                    .contactPersonSalutation
                )
              : getSalutationTitle(
                  salesApplicationPersonalDetailsStepData.value.salutation
                )) ?? "",
        },
        {
          title: "Vorname/Nachname",
          value:
            salesApplicationPersonalDetailsStepData.value.firstName &&
            salesApplicationPersonalDetailsStepData.value.lastName &&
            `${salesApplicationPersonalDetailsStepData.value.firstName} ${salesApplicationPersonalDetailsStepData.value.lastName}`,
        },
        {
          title: "Geburtsdatum",
          value: salesApplicationPersonalDetailsStepData.value.birthDate
            ?.split("-")
            .reverse()
            .join(" / "),
        },
        {
          title: "Tel.",
          value: salesApplicationPersonalDetailsStepData.value.phone,
        },
        {
          title: "E-Mail",
          value: salesApplicationPersonalDetailsStepData.value.emailAddress,
        },
        {
          title: "Kundennummer des Werbenden",
          value: salesApplicationPersonalDetailsStepData.value.accountCustomer,
        },
      ].filter((item) => !!item.value)
    );

    const companyDetails = computed(() =>
      [
        // {
        //   title: "Anrede",
        //   value: getSalutationTitle(
        //     salesApplicationPersonalDetailsStepData.value.salutation
        //   ),
        // },
        {
          title: "Firmenname",
          value: salesApplicationPersonalDetailsStepData.value.companyName,
        },
        {
          title: "Firmenzusatz",
          value: salesApplicationPersonalDetailsStepData.value.companySuffix,
        },
        {
          title: "Branche",
          value: getBranchTypeTitle(
            salesApplicationPersonalDetailsStepData.value.branchType
          ),
        },
      ].filter((item) => !!item.value)
    );

    return {
      isBusinessCustomer,
      details,
      companyDetails,
    };
  },
});
