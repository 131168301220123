







import { defineComponent, computed } from "@vue/composition-api";

import store from "@/store";

export default defineComponent({
  name: "SalesApplicationRouteMessages",
  setup() {
    const errors = computed(() => store.state.salesApplicationRoute.errors);
    const messages = computed(() => store.state.salesApplicationRoute.messages);

    return {
      errors,
      messages,
    };
  },
});
