/* eslint-disable no-useless-escape */
import Vue from "@/helper/initVue";
import store from "@/store";

import SalesApplicationRoute from "@/features/sales-application-route";
import { SalesApplicationRouteConfig } from "@/features/sales-application-route/components/SalesApplicationRoute";

const useDummyConfig = process.env.NODE_ENV === "development";
const dummyConfig = `
  {
    "showFundingProjects": false,
    "preventRedirect": false,
    "links": {
      "cancellationPolicy": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "privacyPolicy": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "creditAssessment": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "egbgbInformation": "http:\/\/www.evo.test\/fileadmin\/user_upload\/Test-Dateien\/dummy.pdf",
      "generalSupplyConditions": {
        "electricity": "http:\/\/www.evo.test\/fileadmin\/user_upload\/EEEEEEEEEEEETest-Dateien\/dummy.pdf",
        "gas": "http:\/\/www.evo.test\/fileadmin\/user_upload\/GGGGGGGGGGGGGGTest-Dateien\/dummy.pdf"
      },
      "supplementaryTerms": {
        "electricity": "http:\/\/www.evo.test\/fileadmin\/user_upload\/EEEEEEEEEEEEEEETest-Dateien\/dummy.pdf",
        "gas": "http:\/\/www.evo.test\/fileadmin\/user_upload\/GGGGGGGGGGGGGTest-Dateien\/dummy.pdf"
      },
      "successPages": {
        "successMoveIn": "\/hauptmenue\/gewinnspiel-torte",
        "successTariffChange": "\/hauptmenue\/gewinnspiel-3",
        "successContractChange": "\/hauptmenue\/gewinnspiel-weberlein"
      },
      "errorPages": {
        "errorPage": "\/angebot-confirm-abschluss\/error-fehler-beim-laden-des-angebotes",
        "errorPageBoniversum": "\/angebot-confirm-abschluss\/error-bonitaetspruefung-nicht-erfolgreich",
        "errorPageAlreadyCompleted": "\/angebot-confirm-abschluss\/error-angebot-wurde-doppelt-abgeschlossen",
        "errorPageValid": "\/angebot-confirm-abschluss\/error-gueltigkeit-des-angebotes-abgelaufen",
        "errorPageLoading": "\/angebot-confirm-abschluss\/error-fehler-beim-laden-des-angebotes"
      }
    }
  }
` as const;

export const parsedDummyConfig = JSON.parse(dummyConfig);

export const initSalesApplicationRouteRoute = () => {
  const el = document.getElementById("sales-application-route");

  if (!el) {
    return;
  }

  try {
    const dataConfig = useDummyConfig
      ? dummyConfig
      : el.getAttribute("data-config");

    if (!dataConfig) {
      return;
    }

    const parsedConfig: SalesApplicationRouteConfig = JSON.parse(dataConfig);

    new Vue({
      el,
      store: store.original, // Inject the classic Vuex store
      render: (h) =>
        h(SalesApplicationRoute, {
          props: {
            config: parsedConfig,
          },
        }),
    });
  } catch (err) {
    console.error('Failed to load module "SalesApplicationRoute"');
  }
};

initSalesApplicationRouteRoute();
