
















































































import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
  reactive,
} from "@vue/composition-api";

import store from "@/store";

import { SalesApplicationStepState } from "../../../SalesApplicationStep";
import SalesApplicationStepContent from "../../../SalesApplicationStepContent";
import SalesApplicationStepForm from "../../../SalesApplicationStepForm";
import SalesApplicationRouteMessages from "../../../SalesApplicationRouteMessages";
import { SalesApplicationOverviewTextWithIconIndicator } from "../../../SalesApplicationOverview";

import { DivisionType } from "@/api/sap";
import { pushEvent } from "@/helper/pushEvent.helper";
import { formatNumber } from "@/helper";
import { get } from "lodash-es";

export default defineComponent({
  name: "SalesApplicationCompleteStep",
  components: {
    SalesApplicationStepContent,
    SalesApplicationStepForm,
    SalesApplicationOverviewTextWithIconIndicator,
    SalesApplicationRouteMessages,
  },
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: SalesApplicationStepState.unvisited,
    },
  },
  setup(props) {
    const formData = ref({
      ...store.getters.salesApplicationCompleteFormData,
    });

    let isSubmitting = ref(false);

    const productData = computed(
      () =>
        store.state.salesApplicationRoute.formData.salesApplicationTariffStep
          .product
    );

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === SalesApplicationStepState.visited
        ) {
          formData.value = {
            ...store.getters.salesApplicationCompleteFormData,
          };
        } else if (currentState === SalesApplicationStepState.visited) {
          store.commit.setSalesApplicationCompleteData({
            ...formData.value,
          });
        }
      }
    );

    const links = computed(
      () => store.state.salesApplicationRoute.config.links
    );

    const completeStepTexts = reactive({
      declarationsOfConsent: {
        instruction: `<a href="${links.value.cancellationPolicy}" target="_blank" rel="noopener"
              >Widerrufsbelehrung mit Muster-Widerrufsformular</a
            >, die <a href="${links.value.privacyPolicy}" target="_blank" rel="noopener">Datenschutzinformationen</a>, den <a href="${links.value.creditAssessment}" target="_blank" rel="noopener">Hinweis zur Bonitätsprüfung</a> und die
            <a href="${links.value.egbgbInformation}" target="_blank" rel="noopener">Informationen nach Art. 246c EGBGB</a>.`,
      },
      informationAndOffers: {
        name: "Einwilligung zur Kontaktierung",
        title:
          "Ja, ich bin an Informationen und Angeboten zu den Leistungen und Produkten der evo interessiert und bin damit einverstanden, dass mich die evo diesbezüglich per E-Mail und/oder telefonisch kontaktiert.",
        description:
          'Möchten Sie ab einem späteren Zeitpunkt keine Informationen und Angebote mehr von uns erhalten? Dann richten Sie Ihren Werbewiderspruch z.B. per Post an Energieversorgung Oberhausen AG, Danziger Str. 31, 46045 Oberhausen oder per Mail an <a href="mailto:kundenservice@evo-energie.de">kundenservice@evo-energie.de</a>.',
      },
      specialContract: {
        electricity: {
          name: "Einwilligung zur AVB",
          title: `Mit der Geltung der <a href="${links.value.generalSupplyConditions?.electricity}" target="_blank" rel="noopener">Allgemeinen Versorgungsbedingungen der Energieversorgung Oberhausen AG für die Belieferung mit Strom</a> bin ich einverstanden.`,
          instructions: undefined,
        },
        gas: {
          name: "Einwilligung zur AVB",
          title: `Mit der Geltung der <a href="${links.value.generalSupplyConditions?.gas}" target="_blank" rel="noopener">Allgemeinen Versorgungsbedingungen der Energieversorgung Oberhausen AG für die Belieferung mit Gas</a> bin ich einverstanden.`,
          instructions: undefined,
        },
      },
      basicSupplierContract: {
        electricity: {
          name: "Einwilligung zur GVV",
          title: `Mit der Geltung der <a href="${links.value.supplementaryTerms?.electricity}" target="_blank" rel="noopener">Ergänzenden Bedingungen der evo zur StromGVV</a> bin ich einverstanden`,
          instructions: `Den Text der Verordnung über Allgemeine Bedingungen für die Grundversorgung von Haushaltskunden und die Ersatzversorgung mit Elektrizität aus dem Niederspannungsnetz (Stromgrundversorgungsverordnung - StromGVV) sowie die Ergänzenden Bedingungen der evo zur <a href="${links.value.supplementaryTerms?.electricity}" target="_blank" rel="noopener">StromGVV finden Sie hier.</a>`,
        },
        gas: {
          name: "Einwilligung zur GVV",
          title: `Mit der Geltung der <a href="${links.value.supplementaryTerms?.gas}" target="_blank" rel="noopener">Ergänzenden Bedingungen der evo zur GasGVV</a> bin ich einverstanden.`,
          instructions: `Den Text der Verordnung über Allgemeine Bedingungen für die Grundversorgung von Haushaltskunden und die Ersatzversorgung mit Gas aus dem Niederdrucknetz (Gasgrundversorgungsverordnung - GasGVV) sowie die Ergänzenden Bedingungen der evo zur <a href="${links.value.supplementaryTerms?.gas}" target="_blank" rel="noopener">GasGVV finden Sie hier.</a>`,
        },
      },
    });

    const isBasicSupplierTariff = computed(
      () =>
        store.getters.salesApplicationTariffFormData.product
          ?.isBasicSupplierTariff
    );

    const contractKey = computed(() =>
      isBasicSupplierTariff.value ? "basicSupplierContract" : "specialContract"
    );

    const contractTypeKey = computed(() =>
      store.getters.salesApplicationTariffFormData.product?.divisionType ===
      DivisionType.Electricity
        ? "electricity"
        : "gas"
    );

    const noticeIsTakenText = ref(
      `Ich habe ${completeStepTexts.declarationsOfConsent.instruction} gelesen und zur Kenntnis genommen`
    );

    function onSubmit() {
      isSubmitting.value = true;
      store.commit.setSalesApplicationCompleteData({
        ...formData.value,
      });

      formData.value.declarationsOfConsentAccepted &&
        store.dispatch.salesApplicationRouteSupplyStart();

      if (productData.value) {
        pushEvent({
          event: "EEtransaction",
          ecommerce: {
            purchase: {
              products: [
                {
                  name: productData.value.name,
                  id: productData.value.id,
                  price: formatNumber(
                    get(productData.value.prices, [
                      "annualCosts",
                      "0",
                      productData.value.priceUnit,
                    ])
                  ),
                  brand: "EVO",
                  category: productData.value.calculatorMode,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
    }

    return {
      contractKey,
      contractTypeKey,
      completeStepTexts,
      formData,
      noticeIsTakenText,
      isSubmitting,
      onSubmit,
    };
  },
});
