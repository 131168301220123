




























import { defineComponent, computed } from "@vue/composition-api";

import {
  SalesApplicationOverviewTextWithIconIndicator,
  SalesApplicationOverviewDataTable,
} from "../../../SalesApplicationOverview";

import { replaceStringWithCharUntil } from "../../../../state/helper/formData.helper";

import store from "@/store";

export default defineComponent({
  name: "SalesApplicationPaymentStepOverview",
  props: {
    paymentMethod: {
      type: String,
    },
    docText: {
      type: String,
      required: true,
    },
    showIban: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    SalesApplicationOverviewTextWithIconIndicator,
    SalesApplicationOverviewDataTable,
  },
  setup(props) {
    const salesApplicationPaymentStepData = computed(
      () =>
        store.state.salesApplicationRoute.formData.salesApplicationPaymentStep
    );

    const docChecked = computed(
      () => salesApplicationPaymentStepData.value.doc
    );

    const paymentData = computed(() =>
      [
        {
          title: "Kontoinhaber",
          value: salesApplicationPaymentStepData.value.accountHolder,
        },
        {
          title: "IBAN",
          value: props.showIban
            ? salesApplicationPaymentStepData.value.iban
            : replaceStringWithCharUntil(
                salesApplicationPaymentStepData.value.iban,
                "X",
                6
              ),
        },
        { title: "BIC", value: salesApplicationPaymentStepData.value.swift },
        {
          title: "Bank",
          value: salesApplicationPaymentStepData.value.bankName,
        },
      ].filter((item) => !!item.value)
    );

    const paymentMethodText = computed(
      () => salesApplicationPaymentStepData.value.paymentMethodData.title
    );

    return {
      docChecked,
      paymentData,
      paymentMethodText,
    };
  },
});
