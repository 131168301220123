


























import { defineComponent, computed, PropType } from "@vue/composition-api";

import { IS_PROTOTYPE } from "@/constants";
import { parsedDummyConfig } from "@/modules/sales-application-route.module";

import store from "@/store";
import { useLoadingEvent } from "@/hooks/useLoadingEvent.hook";
import LoadingOverlay from "@/shared/components/LoadingOverlay";
import TariffCalculatorInfoBox from "@/features/tariff-calculator/components/TariffCalculatorInfoBox";

import SalesApplicationSteps from "../SalesApplicationSteps";

import { SalesApplicationRouteConfig } from ".";

const defaultConfigProps = IS_PROTOTYPE ? parsedDummyConfig : {};

export default defineComponent({
  name: "SalesApplicationRoute",
  components: {
    LoadingOverlay,
    SalesApplicationSteps,
    TariffCalculatorInfoBox,
  },
  props: {
    config: {
      type: Object as PropType<SalesApplicationRouteConfig>,
      default: () => defaultConfigProps as SalesApplicationRouteConfig,
    },
  },
  setup(props, ctx) {
    store.commit.setSalesApplicationRouteTimestamp();
    store.dispatch.setSalesApplicationRouteConfig(props.config);

    // Test with http://www.evo.test/vertriebsanmeldestrecke?token=52540AA090221EEC86DC12551B86C0A6

    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const token = searchParams.get("token");

    store.dispatch.fetchInitialCustomerTariffData(token);

    const { componentIsLoading } = useLoadingEvent({
      root: ctx.root,
      componentID: "SalesApplicationRoute",
    });

    const isLoadingGlobally = computed(
      () => store.state.salesApplicationRoute.isLoadingGlobally
    );

    const showLoadingOverlay = computed(
      () => isLoadingGlobally.value || componentIsLoading.value
    );

    const showSalesApplicationRoute = computed(
      () => store.getters.shouldShowSalesApplicationRoute
    );

    const hasProductSelected = computed(
      () => store.getters.salesApplicationRouteHasProductSelected
    );

    return {
      showSalesApplicationRoute,
      isLoadingGlobally,
      hasProductSelected,
      showLoadingOverlay,
    };
  },
});
