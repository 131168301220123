















import { defineComponent, computed } from "@vue/composition-api";

import store from "@/store";

import { SalesApplicationStepProps } from "../SalesApplicationStep/sales-application-step.types";
import SalesApplicationStep, {
  SalesApplicationStepState,
} from "../SalesApplicationStep";
import { salesApplicationSteps } from "./steps";
import { CustomEditCallback } from "./sales-application-steps.types";
// import { salesApplicationTariffStepCustomEditCallback } from "./steps/SalesApplicationTariffStep";

export default defineComponent({
  name: "SalesApplicationSteps",
  components: {
    SalesApplicationStep,
    ...salesApplicationSteps,
  },
  setup() {
    const steps = computed<SalesApplicationStepProps[]>(
      () => store.state.salesApplicationRoute.steps
    );

    function addCustomEditCallback() {
      const customEditCallbacks: CustomEditCallback[] = [
        // salesApplicationTariffStepCustomEditCallback,
      ];

      customEditCallbacks.forEach((item) => {
        const index = steps.value.findIndex((step) => step.id === item.id);
        if (index > -1) {
          steps.value[index].customToggleEdit = item.customToggleEdit;
        }
      });
    }

    addCustomEditCallback();

    return {
      SalesApplicationStepState,
      steps,
    };
  },
});
