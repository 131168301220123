






















































































import {
  defineComponent,
  PropType,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import { eq, find, lowerCase } from "lodash-es";

import store from "@/store";
import { BaseRadioButtonProps } from "@/shared/components/base/BaseRadioButton";
import LoadingOverlay from "@/shared/components/LoadingOverlay";
import AsyncInputField from "@/shared/components/AsyncInputField";

import { SalesApplicationStepState } from "../../../SalesApplicationStep";
import SalesApplicationStepContent from "../../../SalesApplicationStepContent";
import SalesApplicationStepForm from "../../../SalesApplicationStepForm";
import SalesApplicationIBAN from "../../../SalesApplicationIBAN";

import SalesApplicationPaymentStepOverview from "./SalesApplicationPaymentStepOverview.vue";
import { DeliveryType } from "../SalesApplicationDeliveryStep";

import { ComputedRef } from "@vue/composition-api";

export default defineComponent({
  name: "SalesApplicationPaymentStep",
  components: {
    SalesApplicationStepContent,
    SalesApplicationStepForm,
    SalesApplicationIBAN,
    LoadingOverlay,
    AsyncInputField,
    SalesApplicationPaymentStepOverview,
  },
  props: {
    state: {
      type: String as PropType<SalesApplicationStepState>,
      default: SalesApplicationStepState.unvisited,
    },
  },
  setup(props) {
    const abilities = store.getters.salesApplicationAbilities;
    const paymentAbilities = abilities["salesApplicationPaymentStep"];

    const personalDetails = computed(
      () => store.getters.salesApplicationPersonalDetailsFormData
    );

    const initialData = store.getters.salesApplicationPaymentFormData;

    const formData = ref({
      ...store.getters.salesApplicationPaymentFormData,
    });

    const contractorIsAccountHolder = computed(() => {
      const { firstName, lastName, companyName } = personalDetails.value;

      const transformedCompanyName = lowerCase(companyName).replace(/\s/g, "");

      const transformedFullName = lowerCase(firstName + lastName).replace(
        /\s/g,
        ""
      );

      const transformedAccountHolder = lowerCase(
        formData.value.accountHolder
      ).replace(/\s/g, "");

      return (
        eq(transformedCompanyName, transformedAccountHolder) ||
        eq(transformedFullName, transformedAccountHolder)
      );
    });

    let contractorCustomErrors:
      | ComputedRef<never[]>
      | ComputedRef<string[]> = computed(() => []);

    if (!initialData.accountHolder) {
      contractorCustomErrors = computed(() =>
        !formData.value.accountHolder ||
        contractorIsAccountHolder.value ||
        isTariffChange
          ? []
          : ["Der Vertragsnehmer und Kontoinhaber müssen identisch sein"]
      );
    }

    const isInitiallySepa = formData.value.paymentMethod === "sepa";

    const isDisabled = (fieldName: string, value?: string | boolean) =>
      value
        ? !paymentAbilities.canEdit ||
          paymentAbilities.restrictedFields?.includes(fieldName)
        : !paymentAbilities.canEditWhenEmpty;

    watch(
      () => props.state,
      (currentState, prevState) => {
        if (
          currentState !== prevState &&
          prevState === SalesApplicationStepState.visited
        ) {
          formData.value = {
            ...store.getters.salesApplicationPaymentFormData,
          };
        }
      }
    );

    watch(
      () => formData.value.paymentMethod,
      (currentState, prevState) => {
        if (currentState !== prevState && prevState === "sepa") {
          formData.value = {
            ...formData.value,
            accountHolder: "",
            bankName: "",
            iban: "",
            swift: "",
            doc: false,
          };
        }
      }
    );

    const state = reactive({
      loading: false,
      ibanCustomErrors: [],
    });

    const deliveryType =
      store.getters.salesApplicationDeliveryFormData.deliveryMethod;
    const isTariffChange = deliveryType === DeliveryType.TARIFF_CHANGE;
    const accountHolderRules = computed(() =>
      isTariffChange ? "max:81" : "required|max:81"
    );

    const showSepa = computed(
      () =>
        formData.value.paymentMethod === "sepa" ||
        (paymentAbilities.canEdit && paymentAbilities.canSwitchToSepa)
    );

    const showTransfer = computed(
      () => formData.value.paymentMethod === "transfer"
    );

    const filteredPaymentMethods = [];
    showSepa.value &&
      filteredPaymentMethods.push({
        name: "paymentMethod",
        value: "sepa",
        title: "SEPA-Lastschrift",
      });

    showTransfer.value &&
      filteredPaymentMethods.push({
        name: "paymentMethod",
        value: "transfer",
        title: "Überweisung",
      });

    const paymentMethods = ref<BaseRadioButtonProps[]>(filteredPaymentMethods);

    const customErrors = computed(() => [
      ...state.ibanCustomErrors,
      ...contractorCustomErrors.value,
    ]);

    const sepaSelected = computed(
      () => formData.value.paymentMethod === "sepa"
    );

    const hintText =
      "<b>Hinweis:</b> Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.";

    const docText = `Ich ermächtige die Energieversorgung Oberhausen AG, Zahlungen von meinem Konto mittels SEPA-Lastschriftverfahren einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der Energieversorgung Oberhausen AG auf mein Konto gezogenen Lastschriften einzulösen.<br/><br/>${hintText}`;

    function onSubmit() {
      store.commit.setPaymentData({
        ...formData.value,
        paymentMethodData: find(paymentMethods.value, [
          "value",
          formData.value.paymentMethod,
        ]),
      });
    }

    return {
      formData,
      ...toRefs(state),
      accountHolderRules,
      contractorCustomErrors,
      customErrors,
      paymentMethods,
      sepaSelected,
      isDisabled,
      initialData,
      isInitiallySepa,
      isTariffChange,
      contractorIsAccountHolder,
      docText,
      onSubmit,
    };
  },
});
